<template>
  <div>
    <el-row class="header-row" v-if="!isModal">
      <el-col :span="24">
        <h3 class="el-page-header">Pull List Mappings</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: '/reviews/pulllist-builds' }">Pull List Mapping</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: `/reviews/pulllist-builds/${$route.params.id}` }">{{$route.params.id}}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <div v-if="mainLoading" v-loading="mainLoading">
    </div>

    <template v-if="!mainLoading">
      <input style="margin-top: 10px;" id="fileImport" name="txttoImport" type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" @change="onFileChange" v-if="!isModal">
      <el-tabs style="margin-top: 30px;">
        <el-tab-pane label="Mapping">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form style="margin-top: 20px;" size="mini" label-position="left" label-width="200px" @submit.prevent.native>
                <el-row>
                  <el-col :span="isModal ? 15 : 10">
                    <el-form-item label="Mapping Based On" v-if="!isModal">
                      <div style="margin-top: 3px;">{{localMapping.fileName ? localMapping.fileName : 'Select File'}}</div>
                    </el-form-item>

                    <el-form-item label="Mapping Name">
                      <el-input :disabled="isModal" style="width: 300px;" type="text" v-model="pullListBuild.mappingName" @change="saveMapping()">
                      </el-input>
                    </el-form-item>

                    <el-form-item label="Mapping Type">
                      <hia-el-select :disabled="isModal" @input="handleMappingType()" style="width: 300px;" v-model="pullListBuild.projectTypeId" placeholder="Type"
                        :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.allProjectTypes.list">
                      </hia-el-select>
                    </el-form-item>

                    <!-- <el-form-item label="Mapping Type">
                      <hia-el-select :disabled="isModal" @input="handleMappingType()" style="width: 300px;" v-model="localMapping.mappingType" placeholder="Type"
                        :filterable="true" itemkey="key" label="key" :dropdowns="mappingTypes">
                      </hia-el-select>
                    </el-form-item> -->

                    <el-form-item label="Match Tolerance" v-if="!isModal">
                      <el-input style="width: 300px;" type="number" v-model="localMapping.stringDistance" @change="setMapping(); saveMapping()">
                      </el-input>
                    </el-form-item>

                    <el-form-item label="Number of Preview Rows" v-if="!isModal">
                      <el-input style="width: 300px;" type="number" v-model="localMapping.numberOfPreviewRows" @change="handlePreviewRows()"></el-input>
                      <el-alert type="warning" v-if="previewAlert" style="margin: 10px 0px 20px 0px; width: 300px;" :closable="false">{{previewAlert}}
                      </el-alert>
                    </el-form-item>

                    <el-form-item label="Read All Tabs">
                      <input type="checkbox" name="type" v-model="localMapping.readAllSheets" @change="saveMapping()">
                    </el-form-item>

                  </el-col>
                  <el-col :span="isModal ? 15 : 12">
                    <div>
                      <input :disabled="isModal" type="radio" name="type" value="Single" v-model="localMapping.recordRowStyle" @change="saveMapping()">One Row Per Record
                    </div>
                    <div>
                      <input :disabled="isModal" type="radio" name="type" value="Multi" v-model="localMapping.recordRowStyle" @change="saveMapping()">Multiple Rows Per
                      Record
                    </div>
                    <div v-if="localMapping.recordRowStyle === 'Multi'" style="margin-top: 10px;">
                      <el-form-item label="Patient Number Field">
                        <hia-el-select :disabled="isModal" style="width: 300px;" @input="handlePatientNumberField" v-model="localMapping.patientNumberField"
                          placeholder="Pat Num Field" :clearable="true" :filterable="true" itemkey="key" label="key" :dropdowns="getSourceHeadersDropdown()">
                        </hia-el-select>
                      </el-form-item>
                    </div>
                    <div v-if="localMapping.recordRowStyle === 'Multi'">
                      <el-form-item label="Discharge Date Field">
                        <hia-el-select :disabled="isModal" style="width: 300px;" @input="handlePatientNumberField" v-model="localMapping.dischargeDateField"
                          placeholder="DCD Field" :clearable="true" :filterable="true" itemkey="key" label="key" :dropdowns="getSourceHeadersDropdown()">
                        </hia-el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>

          <el-alert type="warning" v-if="!localMapping.mapping || localMapping.mapping.length === 0" style="margin: 10px 0px 20px 0px" :closable="false">Choose
            File to Start
            Mapping</el-alert>

          <el-row v-if="localMapping.mapping.length > 0" v-loading="loading">
            <h4 class="headerWithBorder">Map Constants</h4>
            <table style="border-collapse: separate; margin-top: 20px; width: 490px">
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Target</th>
                  <th>
                    <div style="position: relative; width: 100%; top: 8px; right: 2px;" v-if="!isModal">
                      <el-dropdown class="right" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <i title="table actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 15px; cursor: pointer;"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-popover placement="bottom" title="Select Target" width="325" trigger="click" v-model="showNewConstantPopover">
                            <el-select filterable style="width: 300px;" v-model="addConstantTarget" placeholder="Select Target" label="key" :dropdowns="unmappedColumns">
                              <el-option v-for="item in unmappedColumns" :key="item" :label="item" :value="item">{{item}}</el-option>
                            </el-select>
                            <div style="margin-bottom: 20px;">
                              <el-button style="margin-top: 15px;" size="mini" type="primary" :disabled="!addConstantTarget" @click="pushConstant">Add
                              </el-button>
                            </div>
                            <el-dropdown-item slot="reference" command="addConstant">Add Constant</el-dropdown-item>
                          </el-popover>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in localMapping.mapping.filter(x => x.type === 'constant')" :key="i">
                  <td style="border-bottom: 1px solid grey; width: 340px; margin-right: 30px;">
                    <div style="margin: 3px 20px 3px 0px;">
                      <el-input :disabled="isModal" type="text" size="mini" v-model="row.constantValue" @change="handleConstant"></el-input>
                    </div>
                  </td>
                  <td :title="JSON.stringify(row)" style="border-bottom: 1px solid grey; max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{row.target[0]}}
                  </td>
                  <td style="border-bottom: 1px solid grey;">
                    <div style="text-align: center;" v-if="!isModal">
                      <i type="button" @click="deleteMappingRow(row)" class="showOnHover el-icon-close delete-button" style="position: relative; top: 1px;"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-row>

          <el-row v-if="localMapping.mapping.length > 0" v-loading="loading">
            <h4 class="headerWithBorder" style="margin-top: 40px;">Source Data Mapping</h4>
            <el-col :span="!isModal ? 10 : 12">
              <table style="border-collapse: separate; margin-top: 20px; width: 90%;">
                <thead>
                  <tr>
                    <th>Sample</th>
                    <th>Source</th>
                    <th>
                      Target
                      <div style="position: relative; width: 100%;" v-if="!isModal">
                        <el-dropdown class="right" trigger="click" @command="handleAction">
                          <span class="el-dropdown-link">
                            <i title="table actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 15px; cursor: pointer;"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="clearMapping">Clear Mapping</el-dropdown-item>
                            <el-popover placement="bottom" title="Select Target" width="325" trigger="click" v-model="showNewSourcePopover">
                              <el-input style="width: 300px;" v-model="newSource" placeholder="Source Property Name">
                              </el-input>
                              <el-input style="width: 300px; margin-top: 15px;" v-model="newSourceSample" placeholder="Sample Data">
                              </el-input>
                              <div style="margin-bottom: 20px;">
                                <el-button style="margin-top: 15px;" size="mini" type="primary" :disabled="!newSource || !newSourceSample" @click="pushNewSource">Add
                                </el-button>
                              </div>
                              <el-dropdown-item slot="reference" command="addSourceProperty">Add Source Property</el-dropdown-item>
                            </el-popover>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in localMapping.mapping.filter(x => x.type === 'mapping')" :key="index">
                    <td style="border-bottom: 1px solid grey; max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><small
                        @click="showDialog(row)" style="cursor: pointer; font-style: italic; margin-right: 30px;">{{row.sourceExample}}</small>
                      <div v-if="row.pivotType">
                        <el-tag size="mini">Pivot {{row.pivotType}}
                        </el-tag>
                        <el-button v-if="!isModal" type="text" class="el-icon-close delete-button" @click="deletePivot(row)"></el-button>
                      </div>
                      <div v-if="row.splitType">
                        <el-tag size="mini">Split {{row.pivotType}} on {{row.delimiter}}</el-tag><button class="deleteButton" @click="deleteDelimiter(row)"></button>
                      </div>
                      <div v-if="row.script">
                        <el-tag size="mini">Script
                        </el-tag><button class="deleteButton" @click="deleteScript(row)"></button>
                      </div>
                    </td>
                    <td style="border-bottom: 1px solid grey; width: 150px;">
                      <div style="margin-right: 30px;" :title="JSON.stringify(row)">{{row.source}} </div>
                      <div v-if="row.delimitedSplit">
                        <div v-for="(item, index) in row.delimitedSplit.slice(0,5)" :key="index"><small style="font-style: italic;">{{item.key}}</small></div>
                      </div>
                      <div v-if="row.pivotTable">
                        <div v-for="(item, index) in row.pivotTable.slice(0,5)" :key="index"><small style="font-style: italic;">{{item.key}}</small></div>
                      </div>
                    </td>
                    <td style="border-bottom: 1px solid grey; width: 150px;">
                      <draggable v-model="row.target" @end="handleEnd" :move="handleMove" group="mapping"
                        :options="{handle: '.dragIcon',animation: 100, direction: 'horizontal'}">
                        <div :class="isModal ? '' : 'dragIcon'" style="width: 100%">{{row.target[0]}}</div>
                      </draggable>
                      <div v-if="row.delimitedSplit">
                        <div v-for="(item, index) in row.delimitedSplit.slice(0,5)" :key="index"><small style="font-style: italic;">{{item.value}}</small></div>
                      </div>
                      <div v-if="row.pivotTable">
                        <div v-for="(item, index) in row.pivotTable.slice(0,5)" :key="index"><small style="font-style: italic;">{{item.value}}</small></div>
                      </div>
                      <div v-if="row.script">
                        <small style="font-style: italic; margin-left: 5px;">{{ getScriptResult(row) }}</small>
                      </div>
                    </td>
                    <td style="border-bottom: 1px solid grey;">
                      <div style="text-align: center; min-width: 20px;" v-if="!isModal">
                        <i type="button" @click="deleteMappingRow(row)" class="showOnHover el-icon-close delete-button" style="position: relative; top: 1px;"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
            <el-col :span="!isModal? 6 : 12">
              <table style="border-collapse: separate; margin-top: 20px; width: 90%">
                <thead>
                  <th>{{localMapping.mappingType}} Unmapped Columns <small>({{unmappedColumns.length}})</small></th>
                </thead>

                <draggable id="unmappedDraggable" v-model="unmappedColumns" @end="handleEnd" :move="handleMove" group="mapping"
                  :options="{handle: '.dragIcon', dragClass: 'no-border', ghostClass: 'no-border',   animation: 100, direction: 'horizontal'}">
                  <tr v-for="(row, index) in unmappedColumns" :key="index">
                    <td style="border-bottom: 1px solid grey;">
                      <div style="margin-right: 50px;" :class="isModal ? '' : 'dragIcon'">{{row}}</div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </el-col>
            <el-col :span="6" v-if="!isModal">
              <h5 style="font-size: 14px; color: #2c3e50; margin-top: 24px; font-weight: bold;">Show Unmapped Columns</h5>
              <el-form style="margin-top: 20px;" size="mini" label-position="left" label-width="200px" @submit.prevent.native>
                <el-row>
                  <el-col>
                    <el-form-item label="DXs?">
                      <input type="checkbox" v-model="localMapping.dxs" @change="handleCheckbox()">
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="PXs?">
                      <input type="checkbox" v-model="localMapping.pxs" @change="handleCheckbox()">
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="CPTs?">
                      <input type="checkbox" v-model="localMapping.cpts" @change="handleCheckbox()">
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="POAs?">
                      <input type="checkbox" v-model="localMapping.poas" @change="handleCheckbox()">
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="Modifiers?">
                      <input type="checkbox" v-model="localMapping.modifiers" @change="handleCheckbox()">
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="Source Preview" lazy>
          <v-client-table class="hiaTable pullListBuildTable" :data="localMapping.sourcePreview" :columns="sourceHeaders" :options="options" v-if="toggle">
          </v-client-table>
        </el-tab-pane>
        <el-tab-pane label="Mapping Preview" lazy>
          <template v-if="fileSheets.length > 0">
            <h4>File Info</h4>
            <table style="border-collapse: separate; margin: 20px 0px 20px 0px;">
              <thead>
                <tr>
                  <th style="border-bottom: 1px solid grey">
                    <div style="margin-right: 20px;">Sheet Name</div>
                  </th>
                  <th style="border-bottom: 1px solid grey"># rows</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in fileSheets" :key="index">
                  <td>
                    <div style="margin-right: 20px;">{{row.name}}</div>
                  </td>
                  <td>{{row.rows}}</td>
                </tr>
                <tr>
                  <td>TOTAL</td>
                  <td>{{getTotal(fileSheets)}}</td>
                </tr>
              </tbody>
            </table>
          </template>

          <el-button v-if="inputFiles.length > 0" style="margin: 10px 0px 20px 0px" size="mini" @click="downloadPullList">Download Pull List</el-button>

          <el-alert type="warning" v-if="inputFiles.length < 1 && !isModal" style="margin: 10px 0px 20px 0px" :closable="false">Choose File Again to Get Pull List</el-alert>

          <ul>
            <li v-for="message in uploadMessages" :key="message">{{message}}</li>
          </ul>

          <el-collapse style="margin-bottom: 10px;">
            <el-collapse-item title="Advanced Filters">
              <el-tabs v-model="activeFilterTab">
                <el-tab-pane label="Source Data" name="source">
                  <h3 style="font-size: 14px; font-weight: bold; margin-bottom: 20px;">Source Data Filter/Transformation Function</h3>
                  <el-button size="mini" style="float: right; margin-top: -40px" @click="scriptHelpVisible = true"><i class="mdi mdi-code-braces"></i></el-button>
                  <p class="code">
                    function (sourceRows) {
                    <textarea :disabled="isModal" spellcheck="false" style="width: 100%;" class="el-textarea__inner" rows="5"
                      v-model.lazy="localMapping.sourceFilterFunction"></textarea>
                    } // needs to return an array of sourceRows
                  </p>
                  <el-button v-if="!isModal" size="mini" style="margin: 5px 0px 10px 0px;" type="primary" @click="handleSourceFilterUpdate">Save and Run</el-button>
                </el-tab-pane>
                <el-tab-pane label="Mapped Data" name="mapped">
                  <h3 style="font-size: 14px; font-weight: bold; margin-bottom: 20px;">Mapped Data Filter/Transformation Function</h3>
                  <el-button size="mini" style="float: right; margin-top: -40px" @click="scriptHelpVisible = true"><i class="mdi mdi-code-braces"></i></el-button>
                  <p class="code">
                    function (mappedRows) {
                    <textarea :disabled="isModal" spellcheck="false" class="el-textarea__inner" rows="5" v-model.lazy="localMapping.mappedFilterFunction"></textarea>
                    } // needs to return an array of mappedRows
                  </p>
                  <el-button v-if="!isModal" size="mini" style="margin: 5px 0px 20px 0px;" type="primary" @click="handleSourceFilterUpdate">Save and Run</el-button>
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
          </el-collapse>

          <v-client-table class="hiaTable pullListBuildTable" :data="pullListPreview" :columns="pullListPreviewColumns" :options="options" v-if="toggle" v-loading="loading">
          </v-client-table>
        </el-tab-pane>

      </el-tabs>

      <el-dialog title="Scripts and Utilities" width="90%" :visible.sync="scriptHelpVisible">
        The following utility functions are available and can be accessed with <el-tag size="small">util.functionName.(param1, param2)</el-tag>

        <p>
          <el-tag size="small">utils.shuffle(array)</el-tag>
          <code><pre>
Params:
  array - The array you want to shuffle
Returns:
  A shuffled version of your array
Example:
let list = [{ PatientNumber: '1', Coder: 'John' },{ PatientNumber: '2', Coder: 'John' }, { PatientNumber: '3', Coder: 'John' }]
let shuffled = utils.shuffle(list)
// shuffled is now randomly sorted
          </pre>
           </code>
        </p>

        <p>
          <el-tag size="small">utils.takeSample(sourceList, sampleByPropertyName, sampleSize)</el-tag>
          <code><pre>
Params:
  sourceList - The source array that you want to randomly sample from
  sampleByPropertyName - The name of the field to group by, e.g., 'Coder'
  sampleSize - The target number of samples per group, e.g., 10 for 10 per 'Coder'
Returns:
  A sampled version of your original array, which may contain full samples if there were enough records, but may only contain partial samples.
  For example, if John only had 3 records but your sample size as 10, only 3 records will be returned.
Example:
let list = [{ PatientNumber: '1', Coder: 'John' },{ PatientNumber: '2', Coder: 'John' }, { PatientNumber: '3', Coder: 'John' }]
let sample = utils.takeSample(list, 'Coder', 10)
// sample is now a randomly sampled sub-list
          </pre>
           </code>
        </p>

        <p>
          <el-tag size="small">utils.getGroupedList(sourceList, propertyName)</el-tag>
          <code><pre>
Params:
  sourceList - The source array that you want to group
  propertyName - The name of the field to group by
Returns:
  A unique list of the propertyName, e.g., Coders
Example:
let list = [{ PatientNumber: '1', Coder: 'John' },{ PatientNumber: '2', Coder: 'John' }, { PatientNumber: '3', Coder: 'John' }, { PatientNumber: '4', Coder: 'James' } ]
let groupedList = utils.getGroupedList(list, 'Coder')
// groupedList is now ['John', 'James']
          </pre></code>
        </p>

        <p>
          <el-tag size="small">utils.getSummary(sourceList, propertyName, groupedList)</el-tag>
          <code><pre>
Params:
  sourceList - The source array that you want to summarize
  propertyName - The name of the field to group by
  groupedList - a list of unique names (use this if you need to show 0's, e.g., if you already filtered the list for Medicare or EM codes, etc)
Returns:
  A summary of counts within each group
Example:
let list = [{ PatientNumber: '1', Coder: 'John' },{ PatientNumber: '2', Coder: 'John' }, { PatientNumber: '3', Coder: 'John' }, { PatientNumber: '4', Coder: 'James' } ]
let groupedList = utils.getSummary(list, 'Coder')
// John   3
// James  1
          </pre></code>
        </p>

        <p>
          <el-tag size="small">utils.addToSample(alreadySampledList, backupList, sampleByPropertyName, sampleSize) </el-tag>
          <code><pre>
Params:
  alreadySampledList - The sample you already created
  backupList - The backup list (e.g., 2nd priority list) you want to get additional samples from
  sampleByPropertyName - The name of the field to group by, e.g., 'Coder',
  sampleSize - The sample size you want to get each group up to
Returns:
  An updated version of the originally sampled list with additional samples. Use this method when you need to get additional samples if the first pass did not produce enough samples.
Example:
let list = [{ PatientNumber: '1', Coder: 'John', Payor: 'MCR' },{ PatientNumber: '2', Coder: 'John', Payor: 'MCR' }, { PatientNumber: '3', Coder: 'John', Payor: 'MCR' }]
let sample = utils.sample(list, 'Coder', 5)
// John now has 3 samples, but we want 5

let backupList = [{ PatientNumber: '1', Coder: 'John', Payor: 'BCBS' },{ PatientNumber: '2', Coder: 'John', Payor: 'BCBS' }, { PatientNumber: '3', Coder: 'John', Payor: 'BCBS'}]
sample = utils.addToSample(sample, backupList, 'Coder', 5)
// John now has 5 samples
          </pre></code>
        </p>

        <p>
          <el-tag size="small">utils.hasEMCode(row) </el-tag>
          <code><pre>
Params:
  row - The pulllist row to test
Returns:
  True/False based on the presence of an EM Code for that row. This is meant to be used as a mappedRows filter.
Example:
let emRecords = mappedRows.filter(x => utils.hasEMCode(row))
// emRecords now contains only records with EM Codes and can be sent to takeSample
          </pre></code>
        </p>

        <p>
          <el-tag size="small">utils.hasSurgicalCptCode(row) </el-tag>
          <code><pre>
Params:
  row - The pulllist row to test
Returns:
  True/False based on the presence of a Surgical CPT for that row. This is meant to be used as a mappedRows filter.
Example:
let surgRecords = mappedRows.filter(x => utils.hasSurgicalCptCode(row))
// surgRecords now contains only records with Surgical CPT codes and can be sent to takeSample
          </pre></code>
        </p>

      </el-dialog>

      <el-dialog :title="`Transform ${dialogData.source}`" :visible.sync="dialogVisible" width="75" :close-on-click-modal="false">
        <div style="margin-bottom: 10px;">{{dialogData.source}}: {{dialogData.sourceExample}}</div>
        <el-tabs>
          <el-tab-pane label="Split">
            <el-form size="mini" style="margin-top: 10px;" @submit.prevent.native>
              <el-form-item label="Delimiter">
                <input type="text" class="el-input__inner" v-model="dialogDelimiter" @input="handleDelimter">
              </el-form-item>

              <label style="margin-top: 10px; font-size: 12px; color: #48576a">Split Type</label>
              <div>
                <input type="radio" name="type" value="DX" v-model="dialogSplitType" @change="handleDelimter()">DXs
                <input type="radio" name="type" value="POA" v-model="dialogSplitType" @change="handleDelimter()">POAs
                <input type="radio" name="type" value="PX" v-model="dialogSplitType" @change="handleDelimter()">PXs
                <input type="radio" name="type" value="CPT" v-model="dialogSplitType" @change="handleDelimter()">CPTs
              </div>

            </el-form>
            <div style="overflow-x: auto;">
              <table style="border-collapse: separate; margin-top: 20px; margin-bottom: 20px;">
                <thead>
                  <tr>
                    <th v-for="item in dialogData.delimitedSplit" :key="item.key">
                      <div style="border-bottom: 1px solid grey; margin-right: 10px;">{{item.key}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="item in dialogData.delimitedSplit" :key="item.key">
                      <div style="margin-right: 10px;">{{item.value}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Pivot">
            <div style="margin-top: 30px;">
              <input type="radio" name="type" value="DX" v-model="dialogPivotType" @change="handlePivot()">DXs
              <input type="radio" name="type" value="POA" v-model="dialogPivotType" @change="handlePivot()">POAs
              <input type="radio" name="type" value="PROC" v-model="dialogPivotType" @change="handlePivot()">PXs
              <input type="radio" name="type" value="CPT" v-model="dialogPivotType" @change="handlePivot()">CPTs
              <input type="radio" name="type" value="MOD" v-model="dialogPivotType" @change="handleModifierPivot()">Modifiers
            </div>

            <div v-if="dialogPivotType === 'MOD'" style="margin-top: 10px;">
              Modifier Source Columns
              <el-select size="mini" :default-first-option="true" class="hia-multiple tightGridSelect" v-model="dialogData.modifierSourceColumns" filterable multiple
                @change="handleModifierPivot()">
                <el-option v-for="item in sourceHeaders" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div style="overflow-x: auto;">
              <table style="border-collapse: separate; margin-top: 20px; margin-bottom: 20px;">
                <thead>
                  <tr>
                    <th v-for="item in dialogData.pivotTable" :key="item.key">
                      <div style="border-bottom: 1px solid grey; margin-right: 10px;">{{item.key}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="item in dialogData.pivotTable" :key="item.key">
                      <div style="margin-right: 10px;">{{item.value}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </el-tab-pane>
          <el-tab-pane label="Script">
            Write a Javascript function to apply a transformation. The source data is available through the <el-tag size="mini">sourceValue</el-tag> param and the
            whole
            source row is
            available
            through the <el-tag size="mini">row</el-tag> param.

            <p>
              function (sourceValue, row) {
              <textarea style="width: 100%;" class="el-textarea__inner" rows="5" v-model.lazy="dialogData.script"></textarea>
              }
            </p>

            <el-button-group style="margin-top: 0px;">
              <el-button size="mini" @click="runScript">Run Script</el-button>
              <el-button size="mini" @click="saveScript">Save Script</el-button>
            </el-button-group>

            <div style="overflow-x: auto;">
              <table style="border-collapse: separate; margin-top: 20px; margin-bottom: 20px;">
                <thead>
                  <tr>
                    <th v-for="item in dialogData.target" :key="item">
                      <div style="border-bottom: 1px solid grey; margin-right: 10px;">{{item}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div style="margin-right: 10px;">{{dialogData.script ? getScriptResult(dialogData) : dialogData.sourceExample}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'PullListBuild',
    props: {
      isModal: {
        type: Boolean
      },
      id: {
        type: Number
      },
      projectId: {}
    },
    data() {
      return {
        scriptHelpVisible: false,
        activeFilterTab: 'mapped',
        previewAlert: null,
        mainLoading: true,
        newSource: null,
        newSourceSample: null,
        showNewSourcePopover: false,
        pullListBuildId: null,
        localMapping: {
          dxs: true,
          pxs: true,
          cpts: false,
          poas: false,
          modifiers: false,
          fileName: null,
          recordRowStyle: 'Single',
          patientNumberField: null,
          readAllTabs: false,
          mapping: [],
          sourceFilterFunction: null,
          mappedFilterFunction: null
        },
        tableLoader: false,
        dialogVisible: false,
        serverRows: [],
        dialogDelimiter: null,
        dialogDelimitedSplit: [],
        dialogSplitType: 'DX',
        dialogPivotType: null,
        dialogData: {
          modifierSourceColumns: []
        },
        finalPullList: {
          groupBy: null,
          maxPerGroup: null
        },
        inputFiles: [],
        fileSheets: [],
        uploadMessages: [],
        targetTemplate: {},
        loading: false,
        toggle: true,
        errorMessage: '',
        addConstantTarget: null,
        showNewConstantPopover: false,
        unmappedColumns: [],
        sourceHeaders: [],
        preview: [],
        previewBackup: [],
        columns: [],
        options: {
          filterByColumn: true,
          perPage: 20,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        pullListPreview: [],
        pullListPreviewColumns: [],
        numWorkers: 1,
        mappingTypes: [{ key: 'Inpatient' }, { key: 'Outpatient' }, { key: 'Pro' }],
        dropdownsNeeded: ['allProjectTypes']
      }
    },
    mounted: async function () {
      this.mainLoading = true

      const id = this.isModal ? this.id : this.$route.params.id

      await Promise.all([
        this.GET_PULLLIST_BUILD(id),
        this.GET_TARGET_TEMPLATES(),
        this.GET_DROPDOWNS(this.dropdownsNeeded)])

      this.localMapping = JSON.parse(this.pullListBuild.pullListBuild)

      this.pullListBuildId = this.$route.params.id

      this.setTargetTemplate()

      if (this.localMapping.mapping.length > 0) {
        this.sourceHeaders = this.localMapping.mapping.filter(x => x.type === 'mapping').map(x => x.source)
        this.setUnmappedColumns()
        this.setPullListPreview()
      }

      this.mainLoading = false
    },
    methods: {
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      ...mapActions('pulllists/', ['GET_PULLLIST_BUILD', 'PUT_PULLLIST_BUILD', 'GET_TARGET_TEMPLATES']),
      showDialog(row) {
        if (this.isModal) {
          return
        }

        this.dialogVisible = true
        this.dialogData = row
        if (!this.dialogData.modifierSourceColumns || this.dialogData.modifierSourceColumns.length === 0) {
          this.dialogData.modifierSourceColumns = [this.dialogData.source]
        }
      },
      getPullListGroupBy() {
        return this.pullListPreviewColumns.map(x => {
          return {
            key: x,
            value: x
          }
        })
      },
      async handlePreviewRows() {
        this.inputFiles = document.getElementById('fileImport').files
        if (this.inputFiles && this.inputFiles.length > 0) {
          this.localMapping.fileName = this.inputFiles[0].name
          this.readImportFilePreview(this.inputFiles[0])
        } else {
          this.previewAlert = 'Select file again to see updated preview'
        }

        this.saveMapping()
      },
      setTargetTemplate() {
        const mappingType = this.getMappingType()
        const map = {
          Inpatient: 'inpatient',
          'Professional Fee': 'pro',
          Outpatient: 'outpatient',
          Ambulatory: 'outpatient'
        }

        const lookupKey = map[mappingType]

        if (lookupKey) {
          this.targetTemplate = this.targetTemplates[lookupKey]
        }
      },
      getMappingType() {
        const row = this.dropdowns.allProjectTypes.list.find(x => x.key === this.pullListBuild.projectTypeId)
        if (row) {
          return row.value
        }
      },
      handleMappingType() {
        this.setTargetTemplate()
        this.setUnmappedColumns()
        this.saveMapping()
      },
      async handleAction(action) {
        if (action === 'clearMapping') {
          await this.$confirm('Clear all target mappings?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
          this.localMapping.mapping = this.localMapping.mapping.map(x => {
            x.target = []
            x.pivotType = null
            x.pivotTable = []
            x.splitType = null
            x.delimitedSplit = []
            return x
          })
          this.setUnmappedColumns()
        }
      },
      runSourceFilter() {
        try {
          let fn = eval(`(sourceRows) => {${this.localMapping.sourceFilterFunction}}`) // eslint-disable-line
          const res = fn(this.localMapping.sourcePreview)
          console.log(res)
        } catch (e) {
          console.error('Error executing source filter function', e)
        }
      },
      runScript() {
        try {
          let fn = eval(`(sourceValue, row) => {${this.dialogData.script}}`) // eslint-disable-line
          fn(this.dialogData.sourceExample)
        } catch (e) {
          console.error('Error executing user defined mapping function', e)
        }
      },
      saveScript() {
        this.saveMapping()
      },
      getScriptResult(row) {
        if (row.script) {
          const sampleRow = this.localMapping && this.localMapping.sourcePreview.length > 0 ? this.localMapping.sourcePreview[0] : {}
          try {
            let fn = eval(`(sourceValue, row) => {${row.script}}`) // eslint-disable-line
            return fn(row.sourceExample, sampleRow)
          } catch (e) {
            console.error('Error executing user defined mapping function', e)
          }
        }
      },
      deleteScript(row) {
        row.script = null
        this.setPullListPreview()
        this.saveMapping()
      },
      getSourceHeadersDropdown() {
        return this.sourceHeaders.map(x => {
          return {
            key: x,
            value: x
          }
        })
      },
      async deleteMappingRow(row) {
        await this.$confirm(`Permanently delete mapping for ${row.source}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        const index = this.localMapping.mapping.findIndex(x => JSON.stringify(row) === JSON.stringify(x))
        this.localMapping.mapping.splice(index, 1)
        this.setUnmappedColumns()
        this.setPullListPreview()
        this.saveMapping()
      },
      getTargetKeysForType(targetType) {
        const keys = this.getTargetKeys().filter(x => x.toUpperCase().includes(targetType))
        return keys
      },
      parseDelimitedValue(row, delimiter, splitType) {
        const parts = row.split(delimiter)
        const keys = this.getTargetKeysForType(splitType)

        const parsedResult = []

        for (let i = 0; i < parts.length; i++) {
          let value = parts[i]
          if (splitType === 'DX') {
            value = value.replace('.', '')
          }

          parsedResult.push({
            key: keys[i],
            value: value
          })
        }

        return parsedResult
      },
      deletePivot(row) {
        row.pivotTable = []
        row.pivotType = null
        row.modifierSourceColumns = []

        this.setPullListPreview()
        this.saveMapping()
      },
      getPivotTable(list, source, pivotType) {
        const rowsWithPivotValues = list.filter(x => x[source] && x[source].toUpperCase() !== 'NULL')

        const keys = this.getTargetKeysForType(pivotType)
        const pivotTable = []

        for (let i = 0; i < rowsWithPivotValues.length; i++) {
          let value = rowsWithPivotValues[i][source].trim()
          if (pivotType === 'DX') {
            value = value.replace('.', '')
          }

          pivotTable.push({
            key: keys[i],
            value: value
          })
        }

        return pivotTable
      },
      getModifiersPivotTable(list, sourceColumns, pivotType) {
        const firstSourceCol = sourceColumns[0]
        const rowsWithPivotValues = list.filter(x => x[firstSourceCol] && x[firstSourceCol].toUpperCase() !== 'NULL')
        const keys = this.getTargetKeysForType(pivotType)

        const pivotTable = []

        for (let i = 0; i < rowsWithPivotValues.length; i++) {
          const concattedMods = []
          sourceColumns.forEach(x => {
            const value = rowsWithPivotValues[i][x]
            if (value && value !== 'NULL') {
              concattedMods.push(value.trim())
            }
          })

          if (concattedMods.length > 0) {
            pivotTable.push({
              key: keys[i],
              value: concattedMods.join(',')
            })
          }
        }

        return pivotTable
      },
      handleModifierPivot() {
        this.dialogData.pivotTable = this.getModifiersPivotTable(this.localMapping.sourcePreview, this.dialogData.modifierSourceColumns, this.dialogPivotType)

        this.dialogData.pivotType = this.dialogPivotType
        this.setPullListPreview()
        this.saveMapping()
      },
      handlePivot() {
        this.dialogData.pivotTable = this.getPivotTable(this.localMapping.sourcePreview, this.dialogData.source, this.dialogPivotType)

        this.dialogData.pivotType = this.dialogPivotType
        this.setPullListPreview()
        this.saveMapping()
      },
      deleteDelimiter(row) {
        row.splitType = null
        row.delimiter = null
        row.delimitedSplit = []
        this.setPullListPreview()
        this.saveMapping()
      },
      handleDelimter() {
        const dialogDelimitedSplit = this.parseDelimitedValue(this.dialogData.sourceExample, this.dialogDelimiter, this.dialogSplitType)

        // update the rows in the mapping because dialogData is a pointer to row
        this.dialogData.delimiter = this.dialogDelimiter
        this.dialogData.splitType = this.dialogSplitType
        this.dialogData.delimitedSplit = dialogDelimitedSplit
        this.setPullListPreview()
        this.saveMapping()
      },
      async downloadPullList() {
        this.loading = true
        this.uploadMessages = []

        setTimeout(async () => {
          for (let i = 0; i < this.fileSheets.length; i++) {
            const start = new Date()
            const sheetName = this.fileSheets[i].name

            if (this.fileSheets[i].rows === 0) {
              this.uploadMessages.push(`Skipping 0 rows sheet ${sheetName}`)
              continue
            }

            this.uploadMessages.push(`Reading sheet ${sheetName}`)
            let stamp = new Date()
            this.readFileAndMapList(this.inputFiles[0], sheetName, async (mappedList) => {
              this.uploadMessages.push(`Mapping records ${sheetName} complete in ${((new Date().getTime() - stamp.getTime()) / 1000)}s`)
              await new Promise(resolve => setTimeout(resolve, 5))

              stamp = new Date()
              this.uploadMessages.push(`Downloading sheet ${sheetName}`)

              let allHeaders = []
              mappedList.forEach(x => {
                allHeaders = allHeaders.concat(Object.keys(x))
              })

              const foundHeaders = [...new Set(allHeaders)]

              const finalHeaders = this.getTargetKeys().filter(x => foundHeaders.includes(x))

              const { default: XLSX } = await import(/* webpackChunkName: "xlsx" */'xlsx')

              const ws = XLSX.utils.json_to_sheet(mappedList, { header: finalHeaders })
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, `${sheetName.slice(0, 20)} PullList`)
              XLSX.writeFile(wb, `PullList_${sheetName}.xlsx`)

              this.uploadMessages.push(`Processing ${sheetName} complete in ${((new Date().getTime() - start.getTime()) / 1000)}s`)
              this.loading = false
            })
          }
          // this.loading = false
        }, 50)
      },
      readFileAndMapList(importFile, sheetName, callback) {
        const worker = new Worker('@hiacode/pulllist-worker/index.js', { type: 'module' })
        const message = {
          importFile,
          sheetName,
          opts: { sheets: [sheetName] },
          pullListBuild: this.localMapping,
          targetTemplate: this.targetTemplate,
          operation: 'readFileAndMapList'
        }
        worker.postMessage(message)
        worker.onmessage = (response) => {
          if (response.data.type === 'status') {
            this.uploadMessages.push(response.data.statusMessage)
          } else {
            callback(response.data)
            worker.terminate()
          }
        }
      },
      async readImportFileWorker(importFile, sheetName) {
        return new Promise((resolve, reject) => {
          const worker = new Worker('@hiacode/pulllist-worker/index.js', { type: 'module' })
          worker.postMessage({ importFile, sheetName, opts: { sheets: [sheetName] }, operation: 'readFile' })
          worker.onmessage = (response) => {
            resolve(response.data.json)
            worker.terminate()
          }
        })
      },
      async readImportFile(importFile, sheetName) {
        const { default: XLSX } = await import(/* webpackChunkName: "xlsx" */'xlsx')
        return new Promise((resolve, reject) => {
          const data = importFile.arrayBuffer()
          // return new Promise(async (resolve, reject) => {
          //   const data = await importFile.arrayBuffer()
          const workbook = XLSX.read(data, { sheets: [sheetName] })
          const raw = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { cellText: false, raw: false })
          resolve(raw)
        })
      },
      async readImportFilePreview(importFile) {
        return new Promise((resolve, reject) => {
          this.loading = true
          const worker = new Worker('@hiacode/pulllist-worker/index.js', { type: 'module' })
          worker.postMessage({ importFile, opts: { sheetRows: (parseInt(this.localMapping.numberOfPreviewRows) + 1) }, operation: 'readFile' })
          worker.onmessage = (response) => {
            const workbook = response.data.workbook

            this.fileSheets = workbook.SheetNames.map(x => {
              const ref = workbook.Sheets[x]['!fullref'] || workbook.Sheets[x]['!ref']

              let rowCount = 0
              if (ref) {
                const refParts = ref.split(':')
                rowCount = refParts[1].replace(/\D/g, '')
              }

              return {
                name: x,
                rows: rowCount
              }
            })

            this.localMapping.sourcePreview = response.data.json

            let allHeaders = []
            // get keys from all preview rows in case the first row had some null values
            this.localMapping.sourcePreview.forEach(x => {
              allHeaders = allHeaders.concat(Object.keys(x))
            })

            this.sourceHeaders = [...new Set(allHeaders)]

            this.setMapping()

            this.loading = false
            this.toggle = false
            this.$nextTick(() => {
              this.toggle = true
            })

            this.saveMapping()

            resolve()
            worker.terminate()
          }
        })
      },
      pushConstant() {
        const lastConstantIndex = this.localMapping.mapping.filter(x => x.type === 'constant').length
        this.localMapping.mapping.splice(lastConstantIndex, 0, { type: 'constant', source: this.addConstantTarget, target: [this.addConstantTarget], constantValue: null })
        this.addConstantTarget = null
        this.showNewConstantPopover = false
        this.setUnmappedColumns()
        this.handleConstant()
      },
      handleConstant(row) {
        this.setPullListPreview()
        this.saveMapping()
      },
      handleMove(evt) {
        // don't allow drop into a mapping target that already exists
        if (evt.relatedContext.element && evt.to.id !== 'unmappedDraggable') {
          return false
        }
      },
      handleEnd(evt) {
        this.setPullListPreview()
        this.saveMapping()
      },
      handlePatientNumberField() {
        this.setPullListPreview()
        this.saveMapping()
      },
      handleSourceFilterUpdate() {
        this.setPullListPreview()
        this.saveMapping()
      },
      handleCheckbox() {
        this.setUnmappedColumns()
        this.saveMapping()
      },
      getTargetKeys() {
        let targetKeys = Object.keys(this.targetTemplate).filter(x => x !== 'validations')

        targetKeys = targetKeys.map(x => x.charAt(0).toUpperCase() + x.slice(1))
        targetKeys = targetKeys.filter(x => x !== 'ExistingRecordIds')
        return targetKeys
      },
      setMapping() {
        let targetKeys = this.getTargetKeys()
        targetKeys = targetKeys.filter(x => !this.localMapping.mapping.some(y => y.target.includes(x)))

        const sourceHeadersToMap = this.localMapping.mapping.map(x => x.source)
        this.sourceHeaders.forEach(x => {
          if (!sourceHeadersToMap.some(y => y === x)) {
            sourceHeadersToMap.push(x)
          }
        })

        this.localMapping.mapping = sourceHeadersToMap.map(x => {
          const existingMapping = this.localMapping.mapping.find(y => y.source === x)
          if (existingMapping) {
            return existingMapping
          }

          let target = targetKeys.find(y => {
            return y
              .toUpperCase()
              .replace(/ /g, '')
              .replace(/_/g, '') ===
              x.toUpperCase()
                .replace(/ /g, '')
                .replace(/_/g, '')
          })

          if (!target) {
            target = targetKeys.find(y => {
              const xTransform = x.toUpperCase()
                .replace(/ /g, '')
                .replace(/_/g, '')

              const yTransform = y.toUpperCase()
                .replace(/ /g, '')
                .replace(/_/g, '')

              return this.getStringDistance(xTransform, yTransform) < this.localMapping.stringDistance
            })
          }

          let sourceExample
          const exampleRow = this.localMapping.sourcePreview.find(y => y[x] && y[x].toUpperCase() !== 'NULL')
          if (exampleRow) {
            sourceExample = exampleRow[x]
          }

          if (target) {
            targetKeys = targetKeys.filter(x => x !== target)
            return {
              type: 'mapping',
              source: x,
              sourceExample: sourceExample,
              target: [target]
            }
          }

          return {
            type: 'mapping',
            source: x,
            sourceExample: sourceExample,
            target: []
          }
        })

        this.setUnmappedColumns()
        this.setPullListPreview()
      },
      async setPullListPreview() {
        this.mapListWithWorkers(JSON.parse(JSON.stringify(this.localMapping.sourcePreview)), false, null, false, (mappedList) => {
          this.pullListPreview = mappedList

          let allHeaders = []
          // get keys from all rows to make sure we have all sourceHeaders
          this.pullListPreview.forEach(x => {
            allHeaders = allHeaders.concat(Object.keys(x))
          })

          const foundHeaders = [...new Set(allHeaders)]
          this.pullListPreviewColumns = this.getTargetKeys().filter(x => foundHeaders.includes(x))

          this.toggle = false
          this.$nextTick(() => {
            this.toggle = true
          })
        })
      },
      async mapListWithWorkers(list, stringify = false, sheetName = null, printMessages = false, completeCallback) {
        const worker = new Worker('@hiacode/pulllist-worker/index.js', { type: 'module' })
        const message = {
          list: list,
          pullListBuild: this.localMapping,
          targetTemplate: this.targetTemplate,
          operation: 'mapList'
        }
        worker.postMessage(message)
        worker.onmessage = (response) => {
          if (response.data.type === 'status') {
            if (printMessages) {
              this.uploadMessages.push(response.data.statusMessage)
            }
          } else {
            completeCallback(response.data)
            worker.terminate()
          }
        }
      },
      setUnmappedColumns() {
        let targetKeys = this.getTargetKeys()

        if (!this.localMapping.cpts) {
          targetKeys = targetKeys.filter(x => !x.includes('Cpt'))
        }

        if (!this.localMapping.poas) {
          targetKeys = targetKeys.filter(x => !x.includes('Poa'))
        }

        if (!this.localMapping.modifiers) {
          targetKeys = targetKeys.filter(x => !x.startsWith('Mod'))
        }

        if (!this.localMapping.dxs) {
          targetKeys = targetKeys.filter(x => !x.toUpperCase().includes('DX'))
        }

        if (!this.localMapping.pxs) {
          targetKeys = targetKeys.filter(x => !x.toUpperCase().includes('PROC'))
        }

        if (!this.localMapping.mapping) {
          return targetKeys
        }
        const unmappedTargetKeys = targetKeys.filter(x => !this.localMapping.mapping.some(y => y.target.length > 0 && y.target[0] === x))

        this.unmappedColumns = unmappedTargetKeys
      },
      pushNewSource() {
        this.localMapping.mapping.push({ type: 'mapping', source: this.newSource, sourceExample: this.newSourceSample, target: [] })
        this.newSource = null
        this.newSourceSample = null
        this.showNewSourcePopover = false
        this.setUnmappedColumns()
        this.handleNewSource()
      },
      handleNewSource(row) {
        this.setPullListPreview()
        this.saveMapping()
      },
      async saveMapping() {
        const payload = {
          id: this.pullListBuildId,
          mappingName: this.pullListBuild.mappingName,
          projectTypeId: this.pullListBuild.projectTypeId,
          PullListBuild: JSON.stringify(this.localMapping)
        }
        await this.PUT_PULLLIST_BUILD(payload)

        // window.localStorage.setItem('pullListBuild', JSON.stringify(this.localMapping))
        // window.localStorage.setItem('preview', JSON.stringify(this.localMapping.sourcePreview))
      },
      deleteMapping() {
        window.localStorage.removeItem('pullListBuild')
      },
      getStringDistance(str1, str2) {
        const levenshteinDistance = (str1 = '', str2 = '') => {
          const maxStrLength = Math.max(str1.length, str2.length)
          const track = Array(str2.length + 1).fill(null).map(() =>
            Array(str1.length + 1).fill(null))
          for (let i = 0; i <= str1.length; i += 1) {
            track[0][i] = i
          }
          for (let j = 0; j <= str2.length; j += 1) {
            track[j][0] = j
          }
          for (let j = 1; j <= str2.length; j += 1) {
            for (let i = 1; i <= str1.length; i += 1) {
              const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
              track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
              )
            }
          }
          const dist = track[str2.length][str1.length]
          if ((maxStrLength - dist) > 1) {
            return dist
          }
          return 1000
        }
        return levenshteinDistance(str1, str2)
      },
      onFileChange() {
        this.previewAlert = null
        this.inputFiles = document.getElementById('fileImport').files
        if (!this.inputFiles) {
          return
        }

        this.localMapping.fileName = this.inputFiles[0].name
        this.readImportFilePreview(this.inputFiles[0])
      },
      getTotal(fileSheets) {
        const response = fileSheets.map(x => parseInt(x.rows)).reduce((a, b) => a + b)
        return response
      }
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('pulllists/', ['pullListBuild', 'targetTemplates'])
    },
    watch: {
      id: async function (newVal) {
        this.mainLoading = true
        await this.GET_PULLLIST_BUILD(newVal)
        this.localMapping = JSON.parse(this.pullListBuild.pullListBuild)

        if (this.localMapping.mappingType) {
          this.targetTemplate = this.targetTemplates[this.localMapping.mappingType.toLowerCase()]
        }

        if (this.localMapping.mapping.length > 0) {
          this.sourceHeaders = this.localMapping.mapping.filter(x => x.type === 'mapping').map(x => x.source)
          this.setUnmappedColumns()
          this.setPullListPreview()
        }
        this.mainLoading = false
      }
    }
  }

</script>

<style scoped>
  .code,
  .code textarea {
    width: 100%;
    background-color: #28282b;
    color: white;
    padding: 7px;
    border-radius: 5px;
  }

  .pullListBuildTable>>>table {
    table-layout: auto;
  }

  table {
    font-size: 14px;
  }

  th {
    text-align: left;
  }

  tr:hover>td i.showOnHover {
    display: block;
    cursor: pointer;
  }

  tr>td i.showOnHover {
    display: none;
  }

  .dragIcon {
    cursor: move;
    margin: 1px 0px 0px 5px;
    padding: 0px 0px 0px 0px;
    float: left;
    height: 20px;
    color: #1f2d3d;
  }

  .no-border td {
    border-bottom: unset !important;
  }

  .deleteButton {
    background-image: url("/static/images/cross-inverse.svg");
    background-size: 8px;
    height: 100%;
    height: 10px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .right {
    top: -19px;
    right: 4px;
    position: absolute;
  }

  .headerWithBorder {
    padding-bottom: 5px;
    margin: 20px 0px 5px 0px;
    border-bottom: 1px solid #eee;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: rgb(119, 119, 119);
    width: inherit;
  }
</style>